<template>
  <section>
    <div className="product">
      <img
        src="https://i.imgur.com/EHyR2nP.png"
        alt="The cover of Stubborn Attachments"
      />
      <div className="description">
        <h3>Stubborn Attachments</h3>
        <h5>$20.00</h5>
      </div>
    </div>
    <button id="checkout-button" role="link" @click.prevent="handleClick">
      Checkout
    </button>
  </section>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { myhttp } from "@/data/myhttp.js";
const stripePromise = loadStripe("pk_live_hm0pLTp4i81KLIutxWE9oM52");

export default {
  setup() {
    async function handleClick() {
      const stripe = await stripePromise;
      const response = await myhttp.post("stripe/create");
      const session = response.data; //.json();
      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    }
    return {
      handleClick,
    };
  },
};
</script>

<style></style>
